import React from 'react';
import { useSelector } from 'react-redux'
import { Row, Col, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalViewMessageTrigger from "../../components/modalViewMessageTrigger";
import Utils from '../../../../core/shared/utils';
import {  getShowEditMessageTriggerModal } from '../../../../store/internal';
import { MessageTriggerStatus } from '../../../../core/enums/message-trigger-status';
import ButtonOrderByList from '../../components/buttonOrderByList';
import Loading from '../../../../components/loading';
import { ListName } from '../../../../core/enums/order-sort-by';
import ChannelLabel from '../../components/channelLabel';
import ModalViewMessageTriggerController from '../../components/modalViewMessageTrigger/indexController';
import HomeMessageTriggerReportController from '../homeMessageTriggerReport/indexController';

const ListOfMessagesTriggers = ({
    isLoading,
    values,
    callback,
    viewTriggerModal,
    dataTriggerType,
    templateMessageList,
    currentData,
    currentTemplate,
    emailList,
    emailPrincipal,
    triggers,
    callbackOrderByMessageTriggers,
    actionsActiveColumn,
    updateListfunction,
    messageTriggerClick,
    popover,
    getAllMessageTriggers,
    confirmCancelTrigger,
    setCurrentTriggerInfo,
    showCreateTrigger,
    showEditTriggerModal,
    t,
    statusTranslate,
    showReportModal,
    handleCloseModalReport,
    currentTriggerId,
    currentWhatsappKeysId
}) => {
    return (
        <>
            <HomeMessageTriggerReportController show={showReportModal} handleClose={handleCloseModalReport} currentTriggerId={currentTriggerId} />

            <div style={{ position: 'absolute', marginTop: '-40vh', marginLeft: '-10%' }}>
                <Loading visibility={isLoading} />
            </div>
            { showEditTriggerModal && currentData && <ModalViewMessageTriggerController token={values.token.value} visibility={showEditTriggerModal} callback={callback} viewTriggerModal={viewTriggerModal} dataTriggerType={dataTriggerType} templateMessageList={templateMessageList} data={currentData} emailList={emailList} emailPrincipal={emailPrincipal} template={currentTemplate} currentWhatsappKeysId={currentWhatsappKeysId}/> }
            <div style={{ margin: '2.5vh 0.5vw 0px', padding: '0' }} className="ps-0 ms-0">
                {
                    triggers !== undefined && triggers.length > 0 ?
                        <ListGroup variant="flux" className="TicketsRow">
                            <ListGroup.Item className="TicketsRowCollums mb-3" style={{ border: 'none' }} id="headerList" key="headerList">
                                {/* right padding for scrollbar */}
                                <Row name="listOfTriggersHead">
                                    <Col md={1} className="nopadding me-2" style={{ width: '14%' }}>
                                        <ButtonOrderByList title={t("home_message_trigger.list_of_messages_triggers.type")} columnIdName='message_sending_type' callbackOrderBy={callbackOrderByMessageTriggers} />
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: '10%' }}>
                                        {t("home_message_trigger.list_of_messages_triggers.channel")}
                                    </Col>
                                    <Col md={2} className="nopadding" style={{ width: '16%' }}>
                                        <ButtonOrderByList title={t("home_message_trigger.list_of_messages_triggers.date_progamed")} columnIdName='date_scheduled' callbackOrderBy={callbackOrderByMessageTriggers} />
                                    </Col>
                                    <Col md={2} className="nopadding" style={{ width: '12.5%' }}>
                                        {t("home_message_trigger.list_of_messages_triggers.accountable")}
                                    </Col>
                                    <Col md={2} className="nopadding" style={{ width: '13%' }}>
                                        {t("home_message_trigger.list_of_messages_triggers.recipients")}
                                    </Col>
                                    <Col md={1} className="nopadding" style={{ width: '8%' }}>
                                        {t("home_message_trigger.list_of_messages_triggers.conversion")}
                                    </Col>
                                    <Col md={1} className="nopadding d-flex justify-content-center">
                                        <ButtonOrderByList title={t("home_message_trigger.list_of_messages_triggers.status")} columnIdName='message_sending_status' callbackOrderBy={callbackOrderByMessageTriggers} />
                                    </Col>
                                    {actionsActiveColumn === true &&
                                        <Col className='d-flex justify-content-end' >
                                            {t("home_message_trigger.list_of_messages_triggers.action")}
                                        </Col>
                                    }
                                </Row>
                            </ListGroup.Item>
                            <div id="listOfTriggers" style={{ overflowY: 'auto', height: '42vh' }}>
                                <InfiniteScroll
                                    dataLength={triggers.length}
                                    next={updateListfunction}
                                    hasMore={true}
                                    loader={<h4> </h4>}
                                    scrollableTarget={"listOfTriggers"}
                                    onScroll={ () => { Utils.hidePopoverOnScroll(ListName.ListOfTriggers) } }
                                >
                                    {
                                        <ListGroup variant="flush" className="TicketsRow">
                                            {

                                                triggers.map((item, index) => {
                                                    let date_scheduled = null;
                                                    let formatedScheduledDate = null;
                                                    if (item?.date_scheduled) {
                                                        date_scheduled = new Date(item?.date_scheduled);
                                                        formatedScheduledDate = Utils.formatedDate(date_scheduled) + "  " + Utils.formatTime(date_scheduled).replace(":", "h");
                                                    }
                                                    
                                                    let channels = "N/A", channelId = item?.channel_id;
                                                    switch (item?.channel_id) {
                                                        case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a':
                                                            channels = 'E-mail';
                                                            break;
                                                        case '6977996d-11d1-44cc-a863-2e1d9c24e046':
                                                            channels = 'Telefonia';
                                                            break;
                                                        case '33be81d8-88ac-4e08-bb4d-cf69fd23267f':
                                                            channels = 'WhatsApp';
                                                            break;
                                                        case 'aa1cea93-23de-46aa-af14-d6f766acf5c8':
                                                            channels = 'Facebook';
                                                            break;
                                                        case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2':
                                                            channels = 'Instagram';
                                                            break;
                                                        case '4a7b71bb-6432-4794-ad11-f978b067871d':
                                                            channels = 'Webchat';
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                    
                                                    // let status = item?.status;
                                                    let status_id = item?.status_id;

                                                    // function formatedDate(date_scheduled: any): React.ReactNode {
                                                    //     throw new Error('Function not implemented.');
                                                    // }

                                                    return (
                                                        <ListGroup.Item style={{ color: '#707070' }} id={item?.id} key={item?.id + index.toString()}>
                                                            <Row className='TicketsRowHeight'>
                                                                <Col md={1} className={'align-self-center nopadding oneLineClampEllipsis me-2'} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer', width: '14%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item?.type}
                                                                            </Tooltip>
                                                                        }   
                                                                    >
                                                                        <span>{item?.type}</span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col md={1} className={'d-flex align-self-center nopadding'} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer', width: '10%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {channels}
                                                                            </Tooltip>
                                                                        }   
                                                                    >
                                                                        { ChannelLabel(channels, channelId, { color: 'rgb(112, 112, 112)' }) }
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col md={2} className={'align-self-center nopadding' + (item.bold ? ' bold-list-item' : '')} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer', width: '16%', border: 'red 4px' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {formatedScheduledDate}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {formatedScheduledDate}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col md={2} className={'align-self-center nopadding oneLineClampEllipsis'} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer', width: '12.5%' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item?.employee}
                                                                            </Tooltip>
                                                                        }   
                                                                    >
                                                                        <span className="me-3">
                                                                            {item?.employee ? item?.employee : <>N/A</>}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                <Col md={2} className={'align-self-center nopadding'} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer', width: '13%' }}>
                                                                    {item.number_of_recipients ?
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item?.number_of_recipients}
                                                                                </Tooltip>
                                                                            }   
                                                                        >
                                                                            <span>{item?.number_of_recipients}</span>
                                                                        </OverlayTrigger> :
                                                                        <span>-</span>
                                                                    }
                                                                </Col>
                                                                <Col md={1} className={'align-self-center nopadding'} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer', width: '8%' }}>
                                                                    {item.number_of_ticket_conversions ?
                                                                        <OverlayTrigger
                                                                            key='top'
                                                                            placement='top'
                                                                            overlay={
                                                                                <Tooltip id='tooltip'>
                                                                                    {item?.number_of_ticket_conversions}
                                                                                </Tooltip>
                                                                            }   
                                                                        >
                                                                            <span>{item?.number_of_ticket_conversions}</span>
                                                                        </OverlayTrigger> :
                                                                        <span>-</span>
                                                                    }
                                                                </Col>
                                                                <Col md={1} className={'align-self-center nopadding justify-content-center'} id={status_id + "*message_status*" + item.id + "*message_status*" + item.channel_id + "*message_status*" + item.employee} onClick={(e) => messageTriggerClick(e)} style={{ cursor: 'pointer' }}>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {statusTranslate(item?.status)}
                                                                            </Tooltip>
                                                                        }   
                                                                    >
                                                                        <div>
                                                                            {item?.status_id === MessageTriggerStatus.Scheduled ?
                                                                                <div className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#0C2461',
                                                                                    }}>
                                                                                    {t("home_message_trigger.list_of_messages_triggers.progammad")}
                                                                                </div> : 
                                                                                item?.status_id === MessageTriggerStatus.Canceled ?
                                                                                <div className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#FFD4B0 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#FF8119',
                                                                                    }}>
                                                                                        {t("home_message_trigger.list_of_messages_triggers.cancel")}
                                                                                </div>:
                                                                                <div className="d-flex justify-content-center py-1"
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        background: '#EEFFF7 0% 0% no-repeat padding-box',
                                                                                        borderRadius: '3px',
                                                                                        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                        letterSpacing: '0px',
                                                                                        color: '#2A9562',
                                                                                    }}>
                                                                                        {t("home_message_trigger.list_of_messages_triggers.fired")}
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </Col>
                                                                {actionsActiveColumn === true &&
                                                                    <Col className='d-flex justify-content-end' >
                                                                        <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item?.status, getAllMessageTriggers, confirmCancelTrigger)} >
                                                                            <Button id={item?.id + "*ID*" + item?.status + "*ID*" + item?.status_id + "*ID*" + item?.channel_id + "*ID*" + item?.employee} onClick={(e) => setCurrentTriggerInfo(e)} className='nopadding d-flex justify-content-end align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <circle cx="12" cy="12" r="1" />
                                                                                    <circle cx="12" cy="19" r="1" />
                                                                                    <circle cx="12" cy="5" r="1" />
                                                                                </svg>
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                        </ListGroup.Item>
                                                    );
                                                })}
                                        </ListGroup>

                                    }
                                </InfiniteScroll>
                            </div>
                        </ListGroup>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '48vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col className='d-flex justify-content-center' md={1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="4vw" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#4a69bd" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="10" y1="14" x2="21" y2="3" />
                                        <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("home_message_trigger.list_of_messages_triggers.no_results")} <Button className="buttonAsText3 nopadding mb-1" onClick={showCreateTrigger}>{t("home_message_trigger.list_of_messages_triggers.add_trigger")}</Button></p>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </>
    );

}

export default ListOfMessagesTriggers;
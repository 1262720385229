import { useEffect, useState } from "react";
import ListOfSummary from ".";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constsRouters from "../../../../../routes/constsRouter";

import { getToken } from "../../../../../store/token";
import { getLanguageEmployee } from "../../../../../store/employee";
import { DataTagIdInterface, TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { Channels } from "../../../../../core/enums/channels";
import { t } from "i18next";
import UserService from "../../../../../services/user-service";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { setShowRegisterSummary } from "../../../../../store/internal";
import { socket } from "../../../../../core/context/socket-context";

const AppRequesterConst = new AppRequesterController();

const ListOfSummaryController = (
    props,
) => {
    const [isLoading, setIsLoading] = useState(false)

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
        employee: {
            employee_language: useSelector(getLanguageEmployee),
        },

    };
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [summaries, setSummaries] = useState([]);

    const viewSummaryPage = (e: { currentTarget: { id: string; }; }) => { /* ir para pagina de sumario individual */
        let summaryId = e.currentTarget.id.split("*ID*")[1];
        navigate(constsRouters.routers.summaryView.path, { state: props.data.filter(item => item.id === summaryId)[0] });
    }

    const showRegisterSummaryModal = () => {
        dispatch(setShowRegisterSummary(true));
    }


    /* ajustar data para o idioma */
    const dateConvert = (date: string) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString(values.employee.employee_language)
    }

    const stroke = "#1E1E1E";
    const iconLen = "22";
    const iconLenAll = "22";


    const allSvgs = [
        {
            id: Channels.WhatsApp,
            svg: (
                <svg id="Grupo_11499" data-name="Grupo 11499"
                    xmlns="http://www.w3.org/2000/svg" width={iconLenAll}
                    height={iconLenAll} viewBox="0 0 17.117 17.117">
                    <path id="Caminho_9745" data-name="Caminho 9745"
                        d="M0,0H17.117V17.117H0Z" fill="none" />
                    <path id="Caminho_9746" data-name="Caminho 9746"
                        d="M3,15.843l1.177-2.71A6.419,6.419,0,1,1,6.6,15.2L3,15.843"
                        transform="translate(-0.86 -0.865)"
                        fill="none" stroke={stroke}
                        strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="1" />
                    <path id="Caminho_9747" data-name="Caminho 9747"
                        d="M9,9.57a.357.357,0,1,0,.713,0V8.857a.357.357,0,1,0-.713,0V9.57a3.566,3.566,0,0,0,3.566,3.566h.713a.357.357,0,0,0,0-.713h-.713a.357.357,0,0,0,0,.713"
                        transform="translate(-2.581 -2.438)"
                        fill="none" stroke={stroke}
                        strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="1" />
                </svg>
            )
        },
        {
            id: Channels.Facebook,
            svg: (
                <svg id="Grupo_11496" data-name="Grupo 11496" xmlns="http://www.w3.org/2000/svg" width={iconLenAll} height={iconLenAll} viewBox="0 2 17.281 17.281">
                    <path id="Caminho_9748" data-name="Caminho 9748" d="M0,0H17.281V17.281H0Z" fill="none" />
                    <path id="Caminho_9749" data-name="Caminho 9749" d="M7,8.04v2.88H9.16v5.04h2.88V10.92H14.2l.72-2.88H12.04V6.6a.72.72,0,0,1,.72-.72h2.16V3H12.76a3.6,3.6,0,0,0-3.6,3.6V8.04H7" transform="translate(-1.96 -0.84)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                </svg>
            )
        },
        {
            id: Channels.Instagram,
            svg: (
                <svg id="Grupo_11497" data-name="Grupo 11497" xmlns="http://www.w3.org/2000/svg" width={iconLenAll} height={iconLenAll} viewBox="0 1 18.91 18.91">
                    <path id="Caminho_9750" data-name="Caminho 9750" d="M0,0H18.91V18.91H0Z" fill="none" />
                    <rect id="Retângulo_18238" data-name="Retângulo 18238" width="13" height="13" rx="4" transform="translate(3 2.91)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <circle id="Elipse_1529" data-name="Elipse 1529" cx="2.5" cy="2.5" r="2.5" transform="translate(7 6.91)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <line id="Linha_421" data-name="Linha 421" y2="0.001" transform="translate(13 5.909)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                </svg>
            )
        },
        {
            id: Channels.Telefonia,
            svg: (
                <svg id="Grupo_11310" data-name="Grupo 11310"
                    xmlns="http://www.w3.org/2000/svg" width={iconLenAll} height={iconLenAll}
                    viewBox="0 0 18.692 18.692">
                    <path id="Caminho_9669" data-name="Caminho 9669"
                        d="M0,0H18.692V18.692H0Z" fill="none" />
                    <path id="Caminho_9670" data-name="Caminho 9670"
                        d="M4.558,4H7.673L9.231,7.894,7.284,9.063a8.567,8.567,0,0,0,3.894,3.894l1.168-1.947,3.894,1.558v3.115a1.558,1.558,0,0,1-1.558,1.558A12.462,12.462,0,0,1,3,5.558,1.558,1.558,0,0,1,4.558,4"
                        transform="translate(-0.663 -0.885)" fill="none"
                        stroke={stroke} strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1" />
                    <line id="Linha_197" data-name="Linha 197" y1="3.894" x2="3.894"
                        transform="translate(11.683 3.115)" fill="none"
                        stroke={stroke} strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1" />
                    <path id="Caminho_9671" data-name="Caminho 9671"
                        d="M16,4h3.115V7.115" transform="translate(-3.538 -0.885)"
                        fill="none" stroke={stroke} strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1" />
                </svg>
            )
        },
        {
            id: Channels.Webchat,
            svg: (
                <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width={iconLenAll} height={iconLenAll} viewBox="0 0 17.974 17.974">
                    <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                    <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                    <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H5.247L3,18.238V10.749A.749.749,0,0,1,3.749,10h1.5" transform="translate(-0.753 -2.511)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                </svg>
            )
        },
        {
            id: Channels.Email,
            svg: (
                <svg id="Grupo_11322" data-name="Grupo 11322"
                    xmlns="http://www.w3.org/2000/svg" width={iconLenAll}
                    height={iconLenAll} viewBox="0 0 18.69 18.69">
                    <path id="Caminho_9699" data-name="Caminho 9699"
                        d="M0,0H18.69V18.69H0Z" fill="none" />
                    <rect id="Retângulo_18003"
                        data-name="Retângulo 18003" width="13.897"
                        height="10.897" rx="2"
                        transform="translate(2 3.793)" fill="none"
                        stroke={stroke} strokeLinecap="round"
                        strokeLinejoin="round" strokeWidth="1" />
                    <path id="Caminho_9700" data-name="Caminho 9700"
                        d="M3,7l7,4.7,7-4.7"
                        transform="translate(-1.048 -1.559)"
                        fill="none" stroke={stroke}
                        strokeLinecap="round" strokeLinejoin="round"
                        strokeWidth="1" />
                </svg>
            )
        }
    ];

    useEffect(() => {
        setSummaries(props.data);
    }, [props.data])

    useEffect(() => {
        socket.socket?.on('finished-summary', (data) => updateStatusSummary(data))
        return () => {
            socket.socket?.off("finished-summary", updateStatusSummary)
        }
    }, [socket.socket?.active])

    const updateStatusSummary = (data) => {
        setSummaries(summary => summary.map(item => {
            if (item.id === data.id) {
                item.status = data.status;
                item.summary_id = data.summary_id;
            }
            return item;
        }))
    }

    const getChannelName = (channel_id, tooltip) => {
        const svgs = [];

        if (channel_id) {
            channel_id.map(id => {
                switch (id) {
                    case Channels.WhatsApp:
                        if (tooltip) {
                            if (svgs.length > 0) {
                                svgs.push(`, ${t('summary_dashboard.view_summary.channels.whatsapp')}`);
                            } else {
                                svgs.push(t('summary_dashboard.view_summary.channels.whatsapp'));
                            }
                        } else {
                            svgs.push(
                                <svg id="Grupo_11499" data-name="Grupo 11499"
                                    xmlns="http://www.w3.org/2000/svg" width={iconLen}
                                    height={iconLen} viewBox="0 0 17.117 17.117">
                                    <path id="Caminho_9745" data-name="Caminho 9745"
                                        d="M0,0H17.117V17.117H0Z" fill="none" />
                                    <path id="Caminho_9746" data-name="Caminho 9746"
                                        d="M3,15.843l1.177-2.71A6.419,6.419,0,1,1,6.6,15.2L3,15.843"
                                        transform="translate(-0.86 -0.865)"
                                        fill="none" stroke={stroke}
                                        strokeLinecap="round" strokeLinejoin="round"
                                        strokeWidth="1" />
                                    <path id="Caminho_9747" data-name="Caminho 9747"
                                        d="M9,9.57a.357.357,0,1,0,.713,0V8.857a.357.357,0,1,0-.713,0V9.57a3.566,3.566,0,0,0,3.566,3.566h.713a.357.357,0,0,0,0-.713h-.713a.357.357,0,0,0,0,.713"
                                        transform="translate(-2.581 -2.438)"
                                        fill="none" stroke={stroke}
                                        strokeLinecap="round" strokeLinejoin="round"
                                        strokeWidth="1" />
                                </svg>
                            );
                        }
                        break;

                    case Channels.Facebook:
                        if (tooltip) {
                            if (svgs.length > 0) {
                                svgs.push(`, ${t('summary_dashboard.view_summary.channels.facebook')}`);
                            } else {
                                svgs.push(t('summary_dashboard.view_summary.channels.facebook'));
                            }
                        } else {
                            svgs.push(
                                <svg id="Grupo_11496" data-name="Grupo 11496" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 2 17.281 17.281">
                                    <path id="Caminho_9748" data-name="Caminho 9748" d="M0,0H17.281V17.281H0Z" fill="none" />
                                    <path id="Caminho_9749" data-name="Caminho 9749" d="M7,8.04v2.88H9.16v5.04h2.88V10.92H14.2l.72-2.88H12.04V6.6a.72.72,0,0,1,.72-.72h2.16V3H12.76a3.6,3.6,0,0,0-3.6,3.6V8.04H7" transform="translate(-1.96 -0.84)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                </svg>
                            );
                        }
                        break;

                    case Channels.Instagram:
                        if (tooltip) {
                            if (svgs.length > 0) {
                                svgs.push(`, ${t('summary_dashboard.view_summary.channels.instagram')}`);
                            } else {
                                svgs.push(t('summary_dashboard.view_summary.channels.instagram'));
                            }
                        } else {
                            svgs.push(
                                <svg id="Grupo_11497" data-name="Grupo 11497" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 1 18.91 18.91">
                                    <path id="Caminho_9750" data-name="Caminho 9750" d="M0,0H18.91V18.91H0Z" fill="none" />
                                    <rect id="Retângulo_18238" data-name="Retângulo 18238" width="13" height="13" rx="4" transform="translate(3 2.91)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                    <circle id="Elipse_1529" data-name="Elipse 1529" cx="2.5" cy="2.5" r="2.5" transform="translate(7 6.91)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                    <line id="Linha_421" data-name="Linha 421" y2="0.001" transform="translate(13 5.909)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                </svg>
                            );
                        }
                        break;

                    case Channels.Telefonia:
                        if (tooltip) {
                            if (svgs.length > 0) {
                                svgs.push(`, ${t('summary_dashboard.view_summary.channels.phone')}`);
                            }
                            else {
                                svgs.push(t('summary_dashboard.view_summary.channels.phone'));
                            }
                        } else {
                            svgs.push(
                                <svg id="Grupo_11310" data-name="Grupo 11310"
                                    xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen}
                                    viewBox="0 0 18.692 18.692">
                                    <path id="Caminho_9669" data-name="Caminho 9669"
                                        d="M0,0H18.692V18.692H0Z" fill="none" />
                                    <path id="Caminho_9670" data-name="Caminho 9670"
                                        d="M4.558,4H7.673L9.231,7.894,7.284,9.063a8.567,8.567,0,0,0,3.894,3.894l1.168-1.947,3.894,1.558v3.115a1.558,1.558,0,0,1-1.558,1.558A12.462,12.462,0,0,1,3,5.558,1.558,1.558,0,0,1,4.558,4"
                                        transform="translate(-0.663 -0.885)" fill="none"
                                        stroke={stroke} strokeLinecap="round"
                                        strokeLinejoin="round" strokeWidth="1" />
                                    <line id="Linha_197" data-name="Linha 197" y1="3.894" x2="3.894"
                                        transform="translate(11.683 3.115)" fill="none"
                                        stroke={stroke} strokeLinecap="round"
                                        strokeLinejoin="round" strokeWidth="1" />
                                    <path id="Caminho_9671" data-name="Caminho 9671"
                                        d="M16,4h3.115V7.115" transform="translate(-3.538 -0.885)"
                                        fill="none" stroke={stroke} strokeLinecap="round"
                                        strokeLinejoin="round" strokeWidth="1" />
                                </svg>
                            );
                        }
                        break;

                    case Channels.Webchat:
                        if (tooltip) {
                            if (svgs.length > 0) {
                                svgs.push(`, ${t('summary_dashboard.view_summary.channels.webchat')}`);
                            } else {
                                svgs.push(t('summary_dashboard.view_summary.channels.webchat'));
                            }
                        } else {
                            svgs.push(
                                <svg id="Grupo_12897" data-name="Grupo 12897" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 0 17.974 17.974">
                                    <path id="Caminho_10311" data-name="Caminho 10311" d="M0,0H17.974V17.974H0Z" fill="none" />
                                    <path id="Caminho_10312" data-name="Caminho 10312" d="M18.238,11.238,15.991,8.991H10.749A.749.749,0,0,1,10,8.242V3.749A.749.749,0,0,1,10.749,3h6.74a.749.749,0,0,1,.749.749v7.489" transform="translate(-2.511 -0.753)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                    <path id="Caminho_10313" data-name="Caminho 10313" d="M11.238,13.745v1.5a.749.749,0,0,1-.749.749H4.749L2.5,18.74V8.749A.749.749,0,0,1,3.249,8h6.74a.749.749,0,0,1,.749.749V11" transform="translate(-0.5 -0.74)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                </svg>
                            );
                        }
                        break;

                    case Channels.Email:
                        if (tooltip) {
                            if (svgs.length > 0) {
                                svgs.push(`, ${t('summary_dashboard.view_summary.channels.email')}`);
                            }
                            else {
                                svgs.push(t('summary_dashboard.view_summary.channels.email'));
                            }
                        } else {
                            svgs.push(
                                <svg id="Grupo_11495" data-name="Grupo 11495" xmlns="http://www.w3.org/2000/svg" width={iconLen} height={iconLen} viewBox="0 1 17.142 17.142">
                                    <path id="Caminho_9743" data-name="Caminho 9743" d="M0,0H17.142V17.142H0Z" fill="none" />
                                    <path id="Caminho_9744" data-name="Caminho 9744" d="M3.57,4H14.713a.714.714,0,0,1,.714.714V13.57a.714.714,0,0,1-.714.714H3.57A.714.714,0,0,1,2.857,13.57V4.714A.714.714,0,0,1,3.57,4h0" transform="translate(-0.785 -0.643)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                    <path id="Caminho_9745" data-name="Caminho 9745" d="M3.57,6.142,8.5,9.285l4.928-3.143" transform="translate(-0.785 -0.714)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                </svg>
                            );
                        }
                        break;
                }
            });
        }
        else {
            if (tooltip) {
                return t('summary_dashboard.view_summary.all');
            }
            else {
                return allSvgs.map(item => item.svg);
            }
        }
        return svgs;
    };



    return (
        <ListOfSummary
            viewSummaryPage={viewSummaryPage}
            callbackOrderBy={props.callbackOrderBy}
            summaries={summaries}
            updateListfunction={props.updateListfunction}
            HasMoreInformation={props.hasMoreInformation}
            isLoading={props.isLoading}
            dateConvert={dateConvert}
            getChannelName={getChannelName}
            showRegisterSummaryModal={showRegisterSummaryModal}
            t={t}
            setShowSummaryFail={props.setShowSummaryFail}
        />
    )

}

export default ListOfSummaryController;
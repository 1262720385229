import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import './style.scss';

const ModalSummaryFail = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-change-status-trigger-type-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('summary_dashboard.summary_fail.title')}
        </Modal.Title>
        <div className="modal-change-status-trigger-type-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <span className="modal-change-status-trigger-type-message">
          {props.t('summary_dashboard.summary_fail.content')}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-change-status-trigger-type-btn-cancel' onClick={() => props.hide(false)}>{props.t('summary_dashboard.summary_fail.close')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSummaryFail;
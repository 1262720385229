import { Button, Col, Container, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import { t } from 'i18next';
import Loading from '../../../../components/loading';
import CallNotificationBar from '../../components/callNotificationBar';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import HomeViewSummaryController from './listOfSummary/indexController';
import { useState } from 'react';
import FilterOptionComponentController from '../../components/filterOptionComponent/indexController';
import { ListName, SortBy } from '../../../../core/enums/order-sort-by';
import ButtonOrderByList from '../../components/buttonOrderByList';
import ModalRegisterSummaryController from '../../components/modalRegisterSummary/indexController';

import { getShowAcquirePlatformComponent, getShowCallNotificationBar, getShowRegisterSummary } from '../../../../store/internal';
import { useSelector } from 'react-redux';
import ListOfSummaryController from './listOfSummary/indexController';
import InfiniteScroll from 'react-infinite-scroll-component';
import Utils from '../../../../core/shared/utils';
import ModalSummaryFail from './modalSummaryFail';

const HomeSummary = ({
    summaries,
    currentState,
    setCurrentState,
    cleanList,
    search,
    showRegisterSummaryModal,
    values,
    callbackOrderBy,
    updateListfunction,
    currentHasMoreInformation,
    isLoading,
    showSummaryFail,
    setShowSummaryFail,
    enableSummary
}) => {

    return (
        <>
            <Container fluid className="nopadding" >
                {/* <Loading visibility={isLoading}></Loading> */}
                <ModalRegisterSummaryController visibility={useSelector(getShowRegisterSummary)} token={values.token.value} updateListfunction={updateListfunction}></ModalRegisterSummaryController>
                <ModalSummaryFail show={showSummaryFail} hide={setShowSummaryFail} t={t} />

                <div className="wrapper" >
                    <div className="content pt-0 ps-0" style={{ /* backgroundColor: "#F9FBff", */ width: '100%' }}>
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)} />
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-3" >
                            <Col md={12} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{`${t("summary_dashboard.title_summary")}`}</div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <ButtonListNotificationController />
                                        <ButtonStatusAgent />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{`${t("summary_dashboard.subtitle_summary")}`}</div>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="ps-3" id="buttonCreateAgent" style={{ marginTop: '5vh', padding: 0, margin: '1vw 0vw 0px 0vw' }}>
                            <Col md={3} >
                                <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                    {t('summary_dashboard.summaries')}
                                    {
                                        enableSummary ? 
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                    {t("summary_dashboard.register_summary_tooltip")}
                                                </Tooltip>
                                            }
                                        >
                                            <Button className="mx-3 nopadding buttonTicketsSec" onClick={showRegisterSummaryModal}>
                                                <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                    <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                    <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </Button>
                                        </OverlayTrigger>
                                        :
                                        <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                                <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                    {t("summary_dashboard.register_summary_modal.warning_no_register_summary")}
                                                </Tooltip>
                                            }
                                        >

                                            <Button className="mx-3 nopadding buttonTicketsSec"  style={{opacity:"0.4"}}>
                                                <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                    <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                    <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </Button>

                                        </OverlayTrigger>
                                    }
                                </p>
                            </Col>
                        </Row>

                        {
                            <div className='FilterTicketsRow' style={{ margin: '2vh 0.5vw', padding: '0' }}>
                                <ListGroup variant="flush" >
                                    <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%', margin: '5px' }}>
                                        <FilterOptionComponentController
                                            optionNames={["Canal", "Tags"]}
                                            currentState={currentState}
                                            setCurrentState={setCurrentState}
                                            values_user={values.user}
                                            filteringTickets={(e, auxObj, action) => search(e, auxObj, action)}
                                            cleanList={cleanList}
                                            values_token_value={values.token.value}
                                            yoursTickets={false}
                                            screen={'summary'}
                                        />
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        }
                        
                        <ListOfSummaryController callbackOrderBy={(column, order) => callbackOrderBy(column, order)} data={summaries} updateListfunction={updateListfunction} hasMoreInformation={currentHasMoreInformation} isLoading={isLoading} setShowSummaryFail={setShowSummaryFail}></ListOfSummaryController>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default HomeSummary;


import React, { FC } from 'react';
import { Col, Container, Modal, Row, Form, FormControl, InputGroup, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import allStyles from '../styles';
import { useSelector } from 'react-redux';
import { getShowTemplateTagModal } from '../../../../../../store/internal';
import Loading from '../../../../../../components/loading';
import { IModalVisualizeTemplateMessage } from './indexModel';
import FormOfModalsTemplateMessageController from '../../../../../homeControlPanel/components/formConfigWhatsapp/formConfigTemplateMessages/formOfModalsTemplateMessage/indexController';
import CreateTagController from '../../../../../homeControlPanel/components/formConfigWhatsapp/formConfigTemplateMessages/formOfModalsTemplateMessage/createTagModal/indexController';
import SelectComponentController from '../../../../components/selectComponent/indexController';
import ListTagsComponentController from '../../../../../homeControlPanel/components/formConfigWhatsapp/formConfigTemplateMessages/formOfModalsTemplateMessage/listTagsComponent/indexController';
import AttachmentsInMessageController from '../../../../../homeControlPanel/components/formConfigWhatsapp/formConfigTemplateMessages/attachmentsInMessage/indexController';

const ModalVisualizeTemplateMessage: FC<IModalVisualizeTemplateMessage> = (props) => {
    return (
        <>
            <Loading visibility={props.isLoading} />
            <Modal show={props.showModal} onHide={() => props.hide()} aria-labelledby="" size="lg" centered={true} >
                <Modal.Header hidden={useSelector(getShowTemplateTagModal)} closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                    <Modal.Title style={allStyles.textBlack}>
                        Template Message
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body hidden={useSelector(getShowTemplateTagModal)} style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                    <Container>
                    <Form onSubmit={() =>{}} noValidate validated={false}>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="title">{props.t('home_departments.template_message_tab.title')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl className="form-control-Default"
                                        size='lg'
                                        type='title'
                                        value={props?.template?.title || '-'}
                                        readOnly={true}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.whatsapp')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <FormControl className="form-control-Default"
                                        size='lg'
                                        type='whatsapp'
                                        value={props.template?.whatsapp_number?.label || '-'}
                                        readOnly={true}
                                    >
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.category')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <FormControl className="form-control-Default"
                                        size='lg'
                                        name="category"
                                        value={props.template?.category?.label || '-'}
                                        readOnly={true}
                                    >
                                </FormControl>
                            </Col>
                            <Col sm={true}>
                                <Form.Label htmlFor="basic-url">{props.t('home_departments.template_message_tab.language')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <FormControl className="form-control-Default"
                                    size='lg'
                                    name="title"
                                    autoComplete='given-name'
                                    value={props.label_language(props.template?.language || '')}
                                    readOnly={true}
                                >
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Col sm={true}>
                                <Form.Label htmlFor="message">{props.t('home_departments.template_message_tab.message')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                <InputGroup className="mb-3">
                                    <FormControl 
                                        style={{ height: '10vh' }}
                                        className="form-control-Default-text-area"
                                        size='lg'
                                        type='text'
                                        as="textarea"
                                        name="message"
                                        maxLength={1024}
                                        value={props?.template?.message || '-'}
                                        readOnly={true}
                                    >
                                    </FormControl>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                            <Form.Label htmlFor="name">
                            {props.t('home_departments.template_message_tab.variables')}
                              <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => {}}>
                                    <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                        <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Button>
                            </Form.Label>
                            <InputGroup className="mb-3" style={{  }}>
                                <ListTagsComponentController initTags={props?.template?.tags} setInitTags={props.setTags} readOnly={true} />
                            </InputGroup>
                        </Row>
                        <Row style={allStyles.textFontGlay} className="mb-3 d-flex justify-content-center">
                            <AttachmentsInMessageController setAttachmentsFilesIds={props.attachmentIdsCallback} previewFiles={props.previewFiles? [props.previewFiles]: []} visualization={true} />
                        </Row>
                        
                        <Row className="d-flex justify-content-center mt-4">
                            <Col xs={6} className="d-flex justify-content-start">
                                <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => props.hide()} >{props.t('home_departments.template_message_tab.back')}</Button>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                            </Col>
                        </Row>
                    </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalVisualizeTemplateMessage;

export enum CodesCxpress{
    DatabaseConnectionInfo="errors.DatabaseConnectionInfoMessage",
    DatabaseConnectionError="errors.DatabaseConnectionErrorMessage",
    DatabaseQueryError="errors.DatabaseQueryErrorMessage",
    DatabaseInsertError="errors.DatabaseInsertErrorMessage",
    DatabaseUpdateError="errors.DatabaseUpdateErrorMessage",
    DatabaseDeleteError="errors.DatabaseDeleteErrorMessage",
    DatabaseTransactionError="errors.DatabaseTransactionErrorMessage",
    DatabaseCommitError="errors.DatabaseCommitErrorMessage",
    DatabaseRollbackError="errors.DatabaseRollbackErrorMessage",

    ServerConnectionInfo="errors.ServerConnectionInfoMessage",
    ServerConnectionError="errors.ServerConnectionErrorMessage",
    ServerRequestError="errors.ServerRequestErrorMessage",
    ServerResponseError="errors.ServerResponseErrorMessage",

    WhatsAppConnectionInfo="errors.WhatsAppConnectionInfoMessage",
    WhatsAppConnectionError="errors.WhatsAppConnectionErrorMessage",
    WhatsAppRequestError="errors.WhatsAppRequestErrorMessage",
    WhatsAppResponseError="errors.WhatsAppResponseErrorMessage",
    WhatsAppRequestBotError="errors.WhatsAppRequestBotErrorMessage",
    WhatsAppResponseBotError="errors.WhatsAppResponseBotErrorMessage",
    WhatsAppKeysSaveError="errors.WhatsAppKeysSaveErrorMessage",
    WhatsAppWebHookError="errors.WhatsAppWebHookErrorMessage",
    WhatsAppWebHookPositusError="errors.WhatsAppWebHookPositusErrorMessage",
    WhatsAppNumbersGetError="errors.WhatsAppNumbersGetErrorMessage",
    WhatsAppTwilioCallBack="errors.WhatsAppTwilioCallBackMessage",
    WhatsAppNotFoundBrokenIdError="errors.WhatsAppNotFoundBrokenIdErrorMessage",
    WhatsAppNumberInUseError="errors.WhatsAppNumberInUseErrorMessage",
    WhatsAppKeysUpdateError="errors.WhatsAppKeysUpdateErrorMessage",

    FacebookConnectionInfo="errors.FacebookConnectionInfoMessage",
    FacebookConnectionError="errors.FacebookConnectionErrorMessage",
    FacebookRequestError="errors.FacebookRequestErrorMessage",
    FacebookResponseError="errors.FacebookResponseErrorMessage",
    FacebookRequestBotError="errors.FacebookRequestBotErrorMessage",
    FacebookResponseBotError="errors.FacebookResponseBotErrorMessage",
    FacebookTokenValidationError="errors.FacebookTokenValidationErrorMessage",
    FacebookWebHookError="errors.FacebookWebHookErrorMessage",
    FacebookListPagesError="errors.FacebookListPagesErrorMessage",
    FacebookNoKeyFoundError="errors.FacebookNoKeyFoundErrorMessage",
    FacebookDuplicateKeysError="errors.FacebookDuplicateKeysErrorMessage",
    FacebookKeysSaveError="errors.FacebookKeysSaveErrorMessage",
    FacebookMissingRequiredFieldError="errors.FacebookMissingRequiredFieldErrorMessage",
    FacebookDuplicatePagesError="errors.FacebookDuplicatePagesErrorMessage",
    FacebookCompanyNotFoundError="errors.FacebookCompanyNotFoundErrorMessage",
    FacebookSaveKeysError="errors.FacebookSaveKeysErrorMessage",

    InstagramConnectionInfo="errors.InstagramConnectionInfoMessage",
    InstagramConnectionError="errors.InstagramConnectionErrorMessage",
    InstagramRequestError="errors.InstagramRequestErrorMessage",
    InstagramResponseError="errors.InstagramResponseErrorMessage",
    InstagramRequestBotError="errors.InstagramRequestBotErrorMessage",
    InstagramResponseBotError="errors.InstagramResponseBotErrorMessage",
    InstagramWebHookError="errors.InstagramWebHookErrorMessage",
    InstagramListPagesError="errors.InstagramListPagesErrorMessage",
    InstagramDuplicatePagesError="errors.InstagramDuplicatePagesErrorMessage",
    InstagramCompanyNotFoundError="errors.InstagramCompanyNotFoundErrorMessage",
    InstagramNoKeyFoundError="errors.InstagramNoKeyFoundErrorMessage",
    InstagramMissingRequiredFieldError="errors.InstagramMissingRequiredFieldErrorMessage",
    InstagramInvalidTokenError="errors.InstagramInvalidTokenErrorMessage",

    WebchatConnectionInfo="errors.WebchatConnectionInfoMessage",
    WebchatConnectionError="errors.WebchatConnectionErrorMessage",
    WebchatRequestError="errors.WebchatRequestErrorMessage",
    WebchatResponseError="errors.WebchatResponseErrorMessage",
    WebchatRequestBotError="errors.WebchatRequestBotErrorMessage",
    WebchatResponseBotError="errors.WebchatResponseBotErrorMessage",
    WebchatCompanyNotFoundError="errors.WebchatCompanyNotFoundErrorMessage",
    WebchatUpdateKeysError="errors.WebchatUpdateKeysErrorMessage",
    WebchatLoadKeysError="errors.WebchatLoadKeysErrorMessage",

    EmailConnectionInfo="errors.EmailConnectionInfoMessage",
    EmailConnectionError="errors.EmailConnectionErrorMessage",
    EmailRequestError="errors.EmailRequestErrorMessage",
    EmailResponseError="errors.EmailResponseErrorMessage",
    EmailSendGridError="errors.EmailSendGridErrorMessage",

    TicketConnectionInfo="errors.TicketConnectionInfoMessage",
    TicketError="errors.TicketErrorMessage",
    TicketCreateError="errors.TicketCreateErrorMessage",
    TicketUpdateError="errors.TicketUpdateErrorMessage",
    TicketDeleteError="errors.TicketDeleteErrorMessage",
    TicketSearchError="errors.TicketSearchErrorMessage",
    TicketSearchByIdError="errors.TicketSearchByIdErrorMessage",
    TicketSearchByChannelError="errors.TicketSearchByChannelErrorMessage",
    TicketSearchByStatusError="errors.TicketSearchByStatusErrorMessage",
    TicketSearchByCompanyError="errors.TicketSearchByCompanyErrorMessage",
    TicketSearchByEmployeeError="errors.TicketSearchByEmployeeErrorMessage",
    TicketSearchByTagError="errors.TicketSearchByTagErrorMessage",
    TicketSearchByLevelError="errors.TicketSearchByLevelErrorMessage",
    TicketSearchByPriorityError="errors.TicketSearchByPriorityErrorMessage",
    TicketUpdateLevelError="errors.TicketUpdateLevelErrorMessage",
    TicketUpdateStatusError="errors.TicketUpdateStatusErrorMessage",
    TicketAlreadyExistsError="errors.TicketAlreadyExistsErrorMessage",
    TicketNotAllowedFacebookOrInstagramError="errors.TicketNotAllowedFacebookOrInstagramErrorMessage",
    TicketNotFoundError="errors.TicketNotFoundErrorMessage",
    TicketTagsNotFoundError="errors.TicketTagsNotFoundErrorMessage",
    TicketBotError="errors.TicketBotErrorMessage",
    TicketN1AlreadyInUseError="errors.TicketN1AlreadyInUseMessage",
    DeletionNotPermitted="errors.DeletionNotPermitted",

    AgentConnectionInfo="errors.AgentConnectionInfoMessage",
    AgentConnectionError="errors.AgentConnectionErrorMessage",
    AgentCreateError="errors.AgentCreateErrorMessage",
    AgentUpdateError="errors.AgentUpdateErrorMessage",
    AgentDeleteError="errors.AgentDeleteErrorMessage",
    AgentDeleteByIdError="errors.AgentDeleteByIdErrorMessage",
    AgentSearchError="errors.AgentSearchErrorMessage",
    AgentCreateLimitError="errors.AgentCreateLimitErrorMessage",
    AgentUpdateEmailInUseError="errors.AgentUpdateEmailInUseErrorMessage",
    AgentUpdateNotFoundError="errors.AgentUpdateNotFoundErrorMessage",
    AgentAssociateSectorError="errors.AgentAssociateSectorErrorMessage",
    AgentCreateSectorNotInTheSameCompanyError="errors.AgentCreateSectorNotInTheSameCompanyErrorMessage",
    AgentDeleteAssociateAgentAndSectorError="errors.AgentDeleteAssociateAgentAndSectorErrorMessage",
    AgentListSectorError="errors.AgentListSectorErrorMessage",
    AgentCreateAlreadyExists="errors.AgentCreateAlreadyExistsMessage",

    ChannelConnectionInfo="errors.ChannelConnectionInfoMessage",
    ChannelConnectionError="errors.ChannelConnectionErrorMessage",
    ChannelCreateError="errors.ChannelCreateErrorMessage",
    ChannelUpdateError="errors.ChannelUpdateErrorMessage",
    ChannelDeleteError="errors.ChannelDeleteErrorMessage",
    ChannelSendEmailError="errors.ChannelSendEmailErrorMessage",

    AuthenticationConnectionInfo="errors.AuthenticationConnectionInfoMessage",
    AuthenticationConnectionError="errors.AuthenticationConnectionErrorMessage",
    AuthenticationCreateError="errors.AuthenticationCreateErrorMessage",
    AuthenticationUpdateError="errors.AuthenticationUpdateErrorMessage",
    AuthenticationDeleteError="errors.AuthenticationDeleteErrorMessage",
    AuthenticationCreateCnpjError="errors.AuthenticationCreateCnpjErrorMessage",
    AuthenticationCreateCnpjInvalid="errors.AuthenticationCreateCnpjInvalidMessage",
    AuthenticationLoginError="errors.AuthenticationLoginErrorMessage",
    AuthenticationUserNotFoundError="errors.AuthenticationUserNotFoundErrorMessage",
    AuthenticationResetPasswordError="errors.AuthenticationResetPasswordErrorMessage",
    AuthenticationEmailNotConfirmedError="errors.AuthenticationEmailNotConfirmedErrorMessage",

    ChatConnectionInfo="errors.ChatConnectionInfoMessage",
    ChatConnectionError="errors.ChatConnectionErrorMessage",
    ChatFileSizeNotAllowed="errors.ChatFileSizeNotAllowedMessage",
    ChatFileDoesNotExist="errors.ChatFileDoesNotExistMessage",
    ChatFileErrorUpload="errors.ChatFileErrorUpload",
    ChatFileExceed="errors.ChatFileExceed",
    ChatFileImageExceed="errors.ChatFileImageExceed",
    ChatFileNotAccepted="errors.ChatFileNotAccepted",
    ChatFileExceedNew="errors.ChatFileExceedNew",
    
    CompanyConnectionInfo="errors.CompanyConnectionInfoMessage",
    CompanyConnectionError="errors.CompanyConnectionErrorMessage",
    CompanyInsertError="errors.CompanyInsertErrorMessage",
    CompanySubdomainNotInformedError="errors.CompanySubdomainNotInformedErrorMessage",
    CompanySubdomainNotRegisteredError="errors.CompanySubdomainNotRegisteredErrorMessage",
    CompanyDeleteError="errors.CompanyDeleteErrorMessage",
    CompanyUserNotFoundError="errors.CompanyUserNotFoundErrorMessage",
    CompanyNotFoundError="errors.CompanyNotFoundErrorMessage",
    CompanyAuthorizationError="errors.CompanyAuthorizationErrorMessage",
    CompanyRegisterEmailError="errors.CompanyRegisterEmailErrorMessage",

    ComunicateExternalConnectionInfo="errors.ComunicateExternalConnectionInfoMessage",
    ComunicateExternalConnectionError="errors.ComunicateExternalConnectionErrorMessage",
    ComunicateExternalAuthenticateDomainError="errors.ComunicateExternalAuthenticateDomainErrorMessage",

    ConsumerConnectionInfo="errors.ConsumerConnectionInfoMessage",
    ConsumerConnectionError="errors.ConsumerConnectionErrorMessage",
    ConsumerCreateError="errors.ConsumerCreateErrorMessage",
    ConsumerUpdateError="errors.ConsumerUpdateErrorMessage",
    ConsumerDeleteError="errors.ConsumerDeleteErrorMessage",
    ConsumerUpdadeWithExistingInformationError="errors.ConsumerUpdadeWithExistingInformationErrorMessage",
    ConsumerNotFoundError="errors.ConsumerNotFoundErrorMessage",
    ConsumerCreateAlreadyExists="errors.ConsumerCreateAlreadyExistsMessage",
    ConsumerAgentAssociate = "errors.ConsumerAgentAssociate",

    ContractConnectionInfo="errors.ContractConnectionInfoMessage",
    ContractConnectionError="errors.ContractConnectionErrorMessage",
    ContractCreateError="errors.ContractCreateErrorMessage",
    ContractUpdateError="errors.ContractUpdateErrorMessage",
    ContractDeleteError="errors.ContractDeleteErrorMessage",
    ContractSearchByCompanyIdError="errors.ContractSearchByCompanyIdErrorMessage",
    ContractSearchByContractIdError="errors.ContractSearchByContractIdErrorMessage",
    ContractDeleteByCompanyIdError="errors.ContractDeleteByCompanyIdErrorMessage",

    BotConnectionInfo="errors.BotConnectionInfoMessage",
    BotConnectionError="errors.BotConnectionErrorMessage",
    BotCreateError="errors.BotCreateErrorMessage",
    BotUpdateError="errors.BotUpdateErrorMessage",
    BotDeleteError="errors.BotDeleteErrorMessage",
    BotSaveWebChatKeysError="errors.BotSaveWebChatKeysErrorMessage",
    BotNotFoundDataToRegistWebChatKeysError="errors.BotNotFoundDataToRegistWebChatKeysErrorMessage",

    UserConnectionInfo="errors.UserConnectionInfoMessage",
    UserError="errors.UserErrorMessage",

    VozxpressConnectionInfo="errors.VozxpressConnectionInfoMessage",
    VozxpressError="errors.VozxpressErrorMessage",
    VozxpressAPIError="errors.VozxpressAPIErrorMessage",
    VozxpressAccountNotFoundError="errors.VozxpressAccountNotFoundErrorMessage",
    VozxpressSendEmailError="errors.VozxpressSendEmailErrorMessage",
    VozxpressCompanyNotFoundError="errors.VozxpressCompanyNotFoundErrorMessage",
    VozxpressCreateTicketError="errors.VozxpressCreateTicketErrorMessage",
    VozxpressInvalidTokenError="errors.VozxpressInvalidTokenErrorMessage",
    VozxpressCreateAudioCallError="errors.VozxpressCreateAudioCallErrorMessage",
    VozxpressUserNotFoundError="errors.VozxpressUserNotFoundErrorMessage",

    MessageSendingConnectionInfo="errors.MessageSendingConnectionInfoMessage",
    MessageSendingConnectionError="errors.MessageSendingConnectionErrorMessage",
    MessageSendingCreateError="errors.MessageSendingCreateErrorMessage",
    MessageSendingUpdateError="errors.MessageSendingUpdateErrorMessage",
    MessageSendingDeleteError="errors.MessageSendingDeleteErrorMessage",
    MessageSendingSearchError="errors.MessageSendingSearchErrorMessage",
    MessageSendingValidateTypeError="errors.MessageSendingValidateTypeErrorMessage",
    MessageSendingInvalidDateError="errors.MessageSendingInvalidDateErrorMessage",

    MessageSendingTypeConnectionInfo="errors.MessageSendingTypeConnectionInfoMessage",
    MessageSendingTypeConnectionError="errors.MessageSendingTypeConnectionErrorMessage",
    MessageSendingTypeCreateError="errors.MessageSendingTypeCreateErrorMessage",
    MessageSendingTypeUpdateError="errors.MessageSendingTypeUpdateErrorMessage",
    MessageSendingTypeDeleteError="errors.MessageSendingTypeDeleteErrorMessage",
    MessageSendingTypeCreateWithNameInUseError="errors.MessageSendingTypeCreateWithNameInUseErrorMessage",

    MetricsTypeConnectionInfo="errors.MetricsTypeConnectionInfoMessage",
    MetricsTypeConnectionError="errors.MetricsTypeConnectionErrorMessage",
    MetricsSearchError="errors.MetricsSearchErrorMessage",

    TemplateMessageConnectionInfo="errors.TemplateMessageConnectionInfoMessage",
    TemplateMessageError="errors.TemplateMessageErrorMessage",
    TemplateMessageCreateError="errors.TemplateMessageCreateErrorMessage",
    TemplateMessageDeleteError="errors.TemplateMessageDeleteErrorMessage",
    TemplateMessageUpdateError="errors.TemplateMessageUpdateErrorMessage",
    TemplateMessagePhoneNumberNotFoundError="errors.TemplateMessagePhoneNumberNotFoundErrorMessage",
    TemplateMessageNotFoundError="errors.TemplateMessageNotFoundErrorMessage",

    TagConnectionInfo="errors.TagConnectionInfoMessage",
    TagError="errors.TagErrorMessage",
    TagCreateError="errors.TagCreateErrorMessage",
    TagDeleteError="errors.TagDeleteErrorMessage",
    TagUpdateError="errors.TagUpdateErrorMessage",
    TagNoTicketsFoundError="errors.TagNoTicketsFoundErrorMessage",
    TagNotFoundError="errors.TagNotFoundErrorMessage",
    TagUpdadeWithExistingTagError="errors.TagUpdadeWithExistingTagErrorMessage",

    errorToSaveWebChatKeys="errors.errorToSaveWebChatKeysMessage",
    notFoundDataToRegistWebChatKeys="errors.notFoundDataToRegistWebChatKeysMessage",

    TicketAlertConnectionInfo="errors.TicketAlertConnectionInfoMessage",
    TicketAlertError="errors.TicketAlertErrorMessage",
    TicketAlertCreateError="errors.TicketAlertCreateErrorMessage",

    PanelConnectionInfo="errors.PanelConnectionInfoMessage",
    PanelError="errors.PanelErrorMessage",
    PanelUpdateOwnerError="errors.PanelUpdateOwnerErrorMessage",
    PanelInvalidTokenError="errors.PanelInvalidTokenErrorMessage",
    PanelCompanyNotFoundError="errors.PanelCompanyNotFoundErrorMessage",

    SectorConnectionInfo="errors.SectorConnectionInfoMessage",
    SectorError="errors.SectorErrorMessage",
    SectorCreateError="errors.SectorCreateErrorMessage",
    SectorUpdateError="errors.SectorUpdateErrorMessage",
    SectorDeleteError="errors.SectorDeleteErrorMessage",
    SectorNotFoundError="errors.SectorNotFoundErrorMessage",
    SectorDuplicatedEmailsError="errors.SectorDuplicatedEmailsErrorMessage",
    SectorListEmailError="errors.SectorListEmailErrorMessage",
    SectorKeywordConnectionInfo="errors.SectorKeywordConnectionInfoMessage",
    SectorKeywordError="errors.SectorKeywordErrorMessage",
    SectorKeywordCreateError="errors.SectorKeywordCreateErrorMessage",
    SectorKeywordUpdateOwnerError="errors.SectorKeywordUpdateOwnerErrorMessage",
    SectorKeywordInvalidTokenError="errors.SectorKeywordInvalidTokenErrorMessage",
    SectorKeywordCompanyNotFoundError="errors.SectorKeywordCompanyNotFoundErrorMessage",
    SectorEmailRegistered="errors.SectorEmailRegistered",
    SectorAlreadyWithThisKey="errors.SectorAlreadyWithThisKey",

    LogCompanyConnectionInfo="errors.LogCompanyConnectionInfoMessage",
    LogCompanyConnectionError="errors.LogCompanyConnectionErrorMessage",
    LogCompanyNoHistoryFoundError="errors.LogCompanyNoHistoryFoundErrorMessage",

    notFoundTrelloKeys="errors.notFoundTrelloKeysMessage",

    defaultError="errors.defaultErrorMessage",

    ConsumerTagNameAlreadyExistError="errors.ConsumerTagNameAlreadyExistErrorMessage",

    SummaryConnectionError="errors.SummaryConnectionErrorMessage",
    SummaryAlredyExistsError="errors.SummaryAlredyExistsErrorMessage",
    SummaryDayInitMoreDayFinalError="errors.SummaryDayInitMoreDayFinalErrorMessage",
}